import React, { ComponentType, ReactNode } from 'react'
import { UiComponent } from '@/components/ui/types'

type Level = 1 | 2 | 3 | 4

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
const getFontSize = (level: Level) => {
  // TODO choose corrent sizes
  let fontSize = 'text-lg'
  switch (level) {
    case 1:
      fontSize = 'text-2xl'
      break
    case 2:
      fontSize = 'text-xl'
      break
    case 3:
      fontSize = 'text-lg'
      break
    default:
      throw new Error('Unsupported level of Heading')
  }
  return fontSize
}

export type HeadingProps = {
  level: Level
  children: ReactNode
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl'
  weight?: 'medium' | 'semibold' | 'bold'
  border?: boolean
} & UiComponent

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4'

export const Heading: ComponentType<HeadingProps> = ({
  level,
  children,
  className = '',
  size = 'lg',
  weight = 'semibold',
  border = false
}) => {
  const Comp = `h${level}` as HeadingTag
  // const fontSize = getFontSize(level)
  return (
    <Comp
      className={`${className} headline text-${size} font-${weight} ${
        border ? 'headline-border' : ''
      }`}
    >
      {children}
    </Comp>
  )
}
